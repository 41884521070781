import React from "react"
import iconBW from "./../../images/logo_bw_ic.png"
import icon from "./../../images/logo_.png"
import logo from "./../../images/logo.png"
import "./style.css"


function PageLoader(props) {
  return (
    <div className="page-loader">
      <div className="icon-wrapper">
        <img src={iconBW} alt="logo" />
        <img src={icon} id="icon-load" alt="logo" />
        <div className="circle-container">
          <div className="white" />
          <div className="circle">
            <div className="wave"></div>
          </div>
        </div>

      </div>
      <div className="logo-container">
        <img src={logo} id="logo-load" alt="logo" />
      </div>
    </div>
  )
}
export default PageLoader 