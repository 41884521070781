import React from "react"
import ReactAnime from 'react-animejs'
import "./style.css"

const {Anime, stagger} = ReactAnime

function Carousel(props) {

  let galleryStyle = {
    width: "calc( 100% * " + (props.gallery.length + 2) + ")"
  }
  let imageStyle = {
    width: "calc( 100% / " + (props.gallery.length + 2) + ")"
  }

  console.log(...Array(props.gallery.length + 2).fill(0).map((_,j) => { return (el, i) => {return  (-100 / (props.gallery.length + 2)) * i + "%"}} ))

  const uniqueClass = "gallery-container " + props.unique

  return(

    <Anime className="carousel-container md-whiteframe-4dp"


      initial={[
        {
          targets: "." + props.unique,
          translateX: [
            ...Array(props.gallery.length + 1).fill(0).map((_,j) => { return (el, i) => {return  (-100 / (props.gallery.length + 2)) * j + "%"}} )
          ],
          duration: 9000,
          easing: 'easeOutExpo',
          loop: true
        }
      ]}
    >

    

      <div className={uniqueClass} style={galleryStyle}>

        {
          props.gallery.map(( image, id) => {
            return (
              <div className="image-container" key={id}  style={imageStyle}>
                <img className="" src={image}/>
              </div>
            )
          })
        }

        <div className="image-container"  style={imageStyle}>
            <img className="md-whiteframe-6dp" src={props.gallery[0]}/>
        </div>
        <div className="image-container"  style={imageStyle}>
            <img className="md-whiteframe-6dp" src={props.gallery[1]}/>
        </div>

      </div>

    </Anime>
  )
}

export default Carousel