import React from "react"
import ReactAnime from 'react-animejs'
import { useLocation } from '@reach/router'

import linkedIn from "./includes/images/linkedin.png"
import facebook from "./includes/images/facebook.png"
import instagram from "./includes/images/instagram.png"
import twitter from "./includes/images/twitter.png"

import "./style.css"

const {Anime, stagger} = ReactAnime


function Footer(props) {


	const location = useLocation()
	const listItems = props.navigationLinks.map( (page, index) => (

	      <li key={page.slug} className={`footer-nav-link${ (location.pathname == page.slug) ? " active" : "" }`}>
	        <a href={page.slug}>{page.label}</a>
	      </li>
	 	)
	)



	return(

		<footer>

			<ul className="footer-navigation">
				{listItems}
			</ul>
			
			<div className="social">
				<a href="https://www.linkedin.com/company/koona-technologies">
					<img src={linkedIn} />
				</a>
				<a href="https://www.linkedin.com/company/koona-technologies">
					<img src={facebook} />
				</a>
				<a href="https://www.linkedin.com/company/koona-technologies">
					<img src={instagram} />
				</a>
				<a href="https://www.linkedin.com/company/koona-technologies">
					<img src={twitter} />
				</a>
			</div>

			<div className="credits">
				<p>&copy; 2021 Kuna Tehnologije j.d.o.o. </p>
			</div>
		</footer>
	)
}

export default Footer