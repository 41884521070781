import * as React from "react"
import ReactAnime from 'react-animejs'

import PageLoader from "./../components/page-loader/pageLoader.js"
import Navigation from "./../components/navigation/navigation.js"
import Carousel from "./../components/carousel/carousel.js"
import Footer from "./../components/footer/footer.js"
import ContactForm from "./../components/contact-form/contactForm.js"

import Seo from "./../components/seo/seo.js"
import { Helmet } from "react-helmet"

import "./../styles/style.css"
import "./../styles/shadows.css"



import logo from "./../images/logo_.png"
import reroot from "./../images/reroot.png"
import point from "./../images/point.jpg"
import gmhawk from "./../images/gmhawk.png"

import relaxmaj1 from "./../images/case-studies/relaxmaj1.png"
import relaxmaj2 from "./../images/case-studies/relaxmaj2.png"
import relaxmaj3 from "./../images/case-studies/relaxmaj3.png"
import relaxmaj4 from "./../images/case-studies/relaxmaj4.png"

import janagreen1 from "./../images/case-studies/janagreen1.png"
import janagreen2 from "./../images/case-studies/janagreen2.png"
import janagreen3 from "./../images/case-studies/janagreen3.png"

import omrcen1 from "./../images/case-studies/omrcen1.png"
import omrcen2 from "./../images/case-studies/omrcen2.png"
import omrcen3 from "./../images/case-studies/omrcen3.png"



import websvg from "./../images/websvg.svg"
import uisvg from "./../images/uisvg.svg"
import complexsvg from "./../images/complexsvg.svg"

let relaxmaj = [relaxmaj1, relaxmaj2, relaxmaj3, relaxmaj4]
let janagreen = [janagreen1, janagreen2, janagreen3]
let omrcen = [omrcen1, omrcen2, omrcen3]

const {Anime, stagger} = ReactAnime


let navigationLinks = [
  {
    slug: "#about-us",
    label: "About us"
  },
  {
    slug: "#portfolio",
    label: "Work"
  },
  {
    slug: "#contact",
    label: "Contact"
  }
]

const IndexPage = () => {

  let heading = ["Empowering", "digital ", "solutions"]

  heading.map(( key, val) => {

    heading[val] = key.replace(/[\s\S]/g, "<span class='letter'>$&</span>")
  })
  

  return (

    <main>

      <Seo />

      <PageLoader />
      <Navigation navigationLinks={navigationLinks}/>

      <section className="hero">
        <div className="hero-gradient"></div>
        <article>
          <Anime className="h1-container"
            initial={[
              {
                targets: "h1 > .letter",
                translateY: [50,0],
                translateZ: 0,
                opacity: [0,1],
                easing: "easeOutExpo",
                duration: 1300,
                delay: (el, i) => 3000 + 40 * i,
              }
            ]}
          >
            {
              heading.map(( content, id) => {
                if(id == heading.length - 1 )
                  return <h1 className="accent" key={id} dangerouslySetInnerHTML={{__html: content}}></h1>
                return <h1 key={id} dangerouslySetInnerHTML={{__html: content}}></h1>
              })
            }

          </Anime>
        </article>
      </section>

      <section className="mission-container" id="about-us">
        <article>
          <h2>Our services</h2>
          {/*<p>
            Our studio has worked together to help businesses grow by providing new identities, 
            developing their brands, creating engaging web experiences and launching new online businesses.
          </p>*/}

          <ul className="services">
            <li className="md-whiteframe-6dp">
              <p>Web Development</p>
              <img src={websvg}/>
            </li>
            <li className="md-whiteframe-6dp">
              <p>Complex Solutions</p>
              <img src={complexsvg}/>
            </li>
            <li className="md-whiteframe-6dp">
              <p>UX & UI Design</p>
              <img src={uisvg}/>
            </li>
          </ul>

        </article>
      </section>

      <section className="work" id="portfolio">
        <article>
          <h2>Our work</h2>

          

          <div className="case-studies-container  md-whiteframe-6dp">
            <div className="case-study">
                <h3>Relax majstor</h3>

                <div className="carousel-wrapper">
                  <Carousel gallery={relaxmaj} unique="relaxmaj" />
                </div>

                <div className="description">

                  <p>
                    "Relaxmajstor" was a campaign for Croatians favourite soft beverage Jana Ice Tea.<br/><br/>
                    The three stage campaign was designed as an open competition for the selection of the 
                    most relaxed person in middle eastern Europe. We animated influencers to achieve
                    greater traction and so managed to reach targeted markets.<br/><br/>

                    For us this was a valuable experience in implementing online competitions and the client 
                    was happy to achieve target key performance indicator values.
                  </p>

                </div>
            </div>            

            <div className="case-study omrcen-container">
                <h3>Omrcen</h3>

                <div className="description">
                  <p>
                    Omrcen is a leading professional baking machinery provider in Eastern Europe.<br/><br/>

                    Their custom tailored webshop was designed specifically for their line of products.
                    We succeeded in bringing their customers ease of access by simplifyign 
                    their products into manageable categories.<br/><br/>

                    Creating this complex web solution was a valuable experince for us and the client 
                    as we worked together to bring their customers closer to their products.
                  </p>
                </div>

                <div className="carousel-wrapper">
                  <Carousel gallery={omrcen} unique="omrcen" />
                </div>

            </div>


            <div className="case-study janagreen-container">
                <h3>Jana recycled</h3>

                <div className="carousel-wrapper">
                  <Carousel gallery={janagreen} unique="janagreen" />
                </div>

                <div className="description">
                  <p>
                    Jana, Eastern Europes most sold water bottle, went green with its new 
                    100% recycled water bottle. <br/><br/>

                    The project included a stunning website with both promotional and educaitonal content.
                    Using new-age technologies we provided gorgeous animations and lightning-fast 
                    loading times leaving every user in awe.
                  </p>
                </div>
            </div>
          </div>




        </article>
      </section>

      <section className="affiliate-partners-container">
        <article className="affiliate-partners">

          <h2>Affiliate partners</h2>
          <div className="partner">
            <img src={reroot}/>
          </div>
          <div className="partner">
            <img src={point}/>
          </div>
          <div className="partner">
            <img src={gmhawk}/>
          </div>
        </article>
      </section>

      <section className="contact-form" id="contact">
        <article className="contact">
          <ContactForm/>
        </article>
      </section>


      <Footer navigationLinks={navigationLinks} />

    </main>
  )
}

export default IndexPage
