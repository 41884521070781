import React, { useEffect, useState } from "react"
import ReactAnime from 'react-animejs'
import { useLocation } from '@reach/router'

import "./style.css"

import logo from "./includes/images/logo.png"
import burger from "./includes/images/burger.png"

import { motion } from "framer-motion"

const {Anime, stagger} = ReactAnime




function Navigation(props) {
  
  const [isNavigationActive, setIsNavigationActive] = React.useState(false)

  useEffect(() => {

    if( isNavigationActive) {
      document.querySelector("#navbar > ul").classList.add( "active")
    } else {
      document.querySelector("#navbar > ul").classList.remove( "active")
    }
    
    console.log( isNavigationActive)

  }, [isNavigationActive])

  const toggleNavigation = () => {
    if( isNavigationActive) {
      setIsNavigationActive( false)
    } else {
      setIsNavigationActive( true)
    }
  }


  const location = useLocation()

  const listItems = props.navigationLinks.map( (page, index) => (

      <li key={page.slug} className={`nav-link${ (location.pathname == page.slug) ? " active" : "" }`}>
        <a onClick={toggleNavigation} href={page.slug}>{page.label}</a>
      </li>
  ))

  let animate1 = {
            targets: ".burger-menu > span:nth-of-type(2)",
            width: (isNavigationActive ? ["100%", "0"] : ["0", "100%"]),
            easing: "easeOutElastic(1, .6)",
            autoplay: false,
            duration: 600
          }
  let animate2 = {
            targets: ".burger-menu > span:nth-of-type(1)",
            rotate: (isNavigationActive ? [0, -45] : [-45, 0]),
            translateX: (isNavigationActive ? [0, "-25%"] : ["-25%", 0]),
            translateY: (isNavigationActive ? [0, "100%"] : ["100%", 0]),
            easing: "easeOutElastic(1, .6)",
            autoplay: false,
            duration: 300,
            delay: -600
          }
  let animate3 = {
            targets: ".burger-menu > span:nth-of-type(3)",
            rotate: (isNavigationActive ? [0, 45] : [45, 0]),
            translateX: (isNavigationActive ? [0, "-25%"] : ["-25%", 0]),
            translateY: (isNavigationActive ? [0, "-100%"] : ["-100%", 0]),
            easing: "easeOutElastic(1, .6)",
            autoplay: false,
            duration: 300,
            delay: -600
          }

  return (
    <div className="navbar" id="navbar">
      <a className="nav-home" href="/"><img src={logo}/></a>

      <div className="burger-menu" onClick={toggleNavigation}
      >
        <motion.span
          animate={{
            rotate: isNavigationActive ? "-45deg" : "0",
            y: isNavigationActive ? "240%" : "0"
          }}
          transition={{ duration: 0.25 }}
          initial={false}
        ></motion.span>
        <motion.span
          animate={{
            width: isNavigationActive ? "0" : "100%"
          }}
          transition={{ duration: 0.25 }}
          initial={false}
        ></motion.span>
        <motion.span
          animate={{
            rotate: isNavigationActive ? "45deg" : "0",
            y: isNavigationActive ? "-240%" : "0"
          }}
          transition={{ duration: 0.25 }}
          initial={false}
        ></motion.span>
      </div>

      <ul className="navigation">
        {listItems}
      </ul>
    </div>
  )
}

export default Navigation 

/*
     


*/