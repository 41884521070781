import React from "react"
import { useState, useRef, useEffect} from "react"

import emailjs from "emailjs-com"

import ReCAPTCHA from "react-google-recaptcha"

import useOnScreen from "./../../hooks/useOnScreen"

import "./style.css"

export default function ContactSection() {
		
	const ref = useRef()
	const formRef = useRef()
	
	const sectionVisible = useOnScreen(ref)
	
	const [ subject, setSubject] = useState( "")	
	const [ name, setName] = useState( "")
	const [ email, setEmail] = useState( "")
	const [ message, setMessage] = useState( "")
	const [ recaptcha, setRecaptcha] = useState( "")
	
	const [ formStyle, setFormStyle] = useState( {})
	
	const [ formVisible, setFormVisible] = useState( true)
	const [ formSent, setFormSent] = useState( false)
	
	useEffect( () => {
		
		let height = 20 + formRef.current?.getBoundingClientRect().height + parseInt( window.getComputedStyle( formRef.current?.firstElementChild).getPropertyValue( "padding-top")) + parseInt( window.getComputedStyle( formRef.current?.lastElementChild.previousElementSibling).getPropertyValue( "padding-bottom"))
		
		setFormStyle( { height: height})
	},[])
	
	const sendEmail = ( e) => {
		
		
		e.preventDefault()
		
		const params = {
			'subject': subject,
			'name': name,
			'email': email,
			'message': message,
			'g-recaptcha-response': recaptcha
		}
				
		emailjs.send(
			  
			  process.env.GATSBY_EMAIL_JS_SERVICE,
			  process.env.GATSBY_EMAIL_JS_TEMPLATE,
			  params,
			  process.env.GATSBY_EMAIL_JS_USER,
		)
		.then( ( result) => {
			  
			setFormSent( true)
			setFormStyle( { height: 0})
			  
		}, ( error) => {
			  
			console.log( error.text);
			  
		})
	}
	
	const finalizeForm = ( e) => {
		
		console.log(e)
		
		if( e.propertyName === "height")
			setFormVisible( false)
	}
	
	const contactForm = ( formVisible && 
		
			<form style={formStyle} ref={formRef} className={`kn-contact-form${ formSent ? " kn-contact-form-sent" : ""}`} onTransitionEnd={finalizeForm}>
			
				<div className="kn-contact-section-form-group">
					<input type="text" id="kn-contact-subject" placeholder=" " value={subject} name="subject" onChange={ event => { 
						let val = event.target.value
						setSubject(val)
					}} required />
					<label htmlFor="kn-contact-subject">Subject</label>
				</div>
				
				<div className="kn-contact-section-form-group">
					<input type="text" id="kn-contact-name" placeholder=" " value={name} name="name"  onChange={ event => {
						let val = event.target.value
						setName(val)
					}} required />
					<label htmlFor="kn-contact-name" >Name</label>
				</div>
				
				<div className="kn-contact-section-form-group">
					<input type="email" id="kn-contact-email" name="email" value={email} placeholder=" "  onChange={ event => {
						let val = event.target.value
						setEmail(val)
					}} required />
					<label htmlFor="kn-contact-email" >Email</label>
				</div>
				
				<div className="kn-contact-section-form-group kn-contact-section-form-group-textarea">
					<textarea id="kn-contact-message" name="message" placeholder=" " rows="4" value={message} onChange={ event => {
						let val = event.target.value
						setMessage(val)
						}} required ></textarea>
					<label htmlFor="kn-contact-message" >Message</label>
				</div>
				
				<div className="kn-contact-section-form-group kn-contact-section-form-group-captcha">
					
					<ReCAPTCHA
						
						sitekey={ process.env.GATSBY_CAPTCHA_SITE_KEY}
						
						onChange={ event => {
							setRecaptcha(event)
						}}
					/>
				</div>
				
				<div className="kn-contact-section-form-group kn-contact-section-form-group-button">
					<input type="submit" id="kn-contact-submit" value="Pošalji" />
				</div>
				
			</form>
		)
	
	return (
		<section ref={ref} className={`kn-contact-section${ sectionVisible && !formSent ? " kn-section-visible" : ""}${ formSent ? " kn-section-form-sent" : ""}`} onSubmit={sendEmail}>
			
			<div className="kn-contact-title-container">
				<h2 className="kn-contact-title">
					{ !formSent ? `Contact us` : ` Thank you`}
				</h2>
			</div>
			
			<div className="kn-contact-outer-section">
			
				{ contactForm}
				
			</div>
			
		</section>
	)
}